import EquipmentApiProxyService from '@/services/EquipmentApiProxyService';
import { cloneDeep } from 'lodash/lang';

const queryString = require('query-string');
const filterNames = ['years', 'models', 'brands', 'types', 'makes'];

export default {
  extractFilterValues(filters) {
    let blankString = 'equipment.filter.blankValue';
    if (typeof filters === 'undefined') {
      return {};
    }
    let localFilter = cloneDeep(filters);
    for (let i = 0; i < filterNames.length; i++) {
      let filter = localFilter[filterNames[i]];
      if (filter) {
        for (let j = 0; j < filter.length; j++) {
          let value = filter[j];
          if (value === blankString) {
            filter[j] = null;
            break;
          }
        }
        localFilter[filterNames[i]] = filter;
      }
    }
    return localFilter;
  },
  getQueryString(pagination) {
    return queryString.stringify(pagination);
  },

  getSearchQueryString(searchQuery, equipmentFilter) {
    let localFilters = this.extractFilterValues(equipmentFilter);
    localFilters['equipmentSearch'] = searchQuery;
    return queryString.stringify({
      filter: JSON.stringify(localFilters),
    });
  },

  getRequestParameters(params) {
    return Object.keys(params)
      .map((key) => key + '=' + encodeURIComponent(params[key]))
      .join('&');
  },

  getEquipment(pagination, searchQuery, equipmentFilter) {
    return EquipmentApiProxyService.get(
      '/equipment?' +
        this.getSearchQueryString(searchQuery, equipmentFilter) +
        '&' +
        this.getQueryString(pagination)
    );
  },
  getEquipmentWithLinkedAssets(
    pagination,
    searchQuery,
    equipmentFilter,
    origination
  ) {
    const query = [
      this.getSearchQueryString(searchQuery, equipmentFilter),
      this.getQueryString(pagination),
    ];
    if (origination) {
      query.push(this.getQueryString({ origination: origination }));
    }
    return EquipmentApiProxyService.get(
      '/equipment/withAssociations?' + query.join('&')
    );
  },
  getDetailedEquipmentWithLinkedAssets(searchQuery, equipmentFilter) {
    return EquipmentApiProxyService.get(
      '/equipment/withAssociations/details?' +
        this.getSearchQueryString(searchQuery, equipmentFilter)
    );
  },
  getDecommissionedEquipment(pagination, searchQuery) {
    return EquipmentApiProxyService.get(
      '/equipment/decommissioned?' +
        this.getSearchQueryString(searchQuery, {}) +
        '&' +
        this.getQueryString(pagination)
    );
  },

  getBasicDecommissionedEquipment(searchQuery) {
    return EquipmentApiProxyService.get(
      '/equipment/basic/decommissioned?' +
        this.getSearchQueryString(searchQuery)
    );
  },

  createEquipment(equipmentDetails) {
    return EquipmentApiProxyService.post('/equipment', equipmentDetails);
  },

  getEquipmentById(equipmentId) {
    return EquipmentApiProxyService.get('/equipment/details/' + equipmentId);
  },

  editEquipment(equipmentEditDetails) {
    return EquipmentApiProxyService.patch(
      '/equipment/update/' + equipmentEditDetails.id,
      {
        name: equipmentEditDetails.name,
        identifier: equipmentEditDetails.identifier,
        description: equipmentEditDetails.description,
        type: equipmentEditDetails.type,
        brand: equipmentEditDetails.brand,
        model: equipmentEditDetails.model,
        year: equipmentEditDetails.year,
      }
    );
  },

  updateEquipment(equipmentEditDetails) {
    return EquipmentApiProxyService.patch(
      '/equipment/update/' + equipmentEditDetails.id,
      { ...equipmentEditDetails }
    );
  },

  decommissionEquipmentById(equipmentIds) {
    return EquipmentApiProxyService.post(
      '/equipment/decommission/bulk',
      equipmentIds
    );
  },

  recommissionEquipment(equipmentIds) {
    return EquipmentApiProxyService.post(
      '/equipment/recommission/bulk',
      equipmentIds
    );
  },

  deleteEquipment(equipmentIds) {
    return EquipmentApiProxyService.delete('/equipment/delete/bulk', {
      data: equipmentIds,
    });
  },

  getEquipmentFilters() {
    return EquipmentApiProxyService.get('/equipment/filters');
  },

  getEquipmentAssociationFilters() {
    return EquipmentApiProxyService.get('/equipment/filters/association');
  },

  getBasicEquipment(searchQuery, equipmentFilter) {
    return EquipmentApiProxyService.get(
      '/equipment/basic?' +
        this.getSearchQueryString(searchQuery, equipmentFilter)
    );
  },

  getEquipmentTypes() {
    return EquipmentApiProxyService.get('/equipment/types');
  },

  getEquipmentTypeSchemas() {
    return EquipmentApiProxyService.get('/equipment/types/schemas');
  },

  getLinkedAssetsByEntityId(entityId) {
    return EquipmentApiProxyService.get('/association/details/' + entityId);
  },

  getVehiclesWithLinkedAssets(
    pagination,
    searchQuery,
    vehicleFilter,
    entityType
  ) {
    return EquipmentApiProxyService.post(
      '/vehicles?' + this.getQueryString(pagination),
      this.getBody(vehicleFilter, searchQuery, entityType)
    );
  },

  getBody(filters, searchQuery, entityType) {
    let localFilters = {};
    if (filters) {
      localFilters = this.extractFilterValues(filters);
    }
    if (searchQuery) {
      localFilters['vehicleSearch'] = searchQuery;
    }
    if (entityType) {
      localFilters['equipmentType'] = entityType;
    }
    return localFilters;
  },

  validateForAssociationReplacements(checkReplacement) {
    return EquipmentApiProxyService.get(
      '/association/add/validate?' + this.getRequestParameters(checkReplacement)
    );
  },

  removeLinkAssetByAssociationId(associationIds) {
    return EquipmentApiProxyService.delete('/association/delete', {
      data: associationIds,
    });
  },

  getUnlinkedEquipmentCountByType(type) {
    return EquipmentApiProxyService.get(
      '/equipment/unlinked/count?type=' + type
    );
  },

  createAssociation(associationRequest) {
    return EquipmentApiProxyService.post(
      '/association/add',
      associationRequest
    );
  },

  getAssociationCountsByType(type) {
    return EquipmentApiProxyService.get(
      '/association/counts/by-type?type=' + type
    );
  },

  getEntitlementByEquipmentId(id) {
    return EquipmentApiProxyService.get('/entitlement/fetch/' + id);
  },

  getSubscribedCountAndFilter() {
    return EquipmentApiProxyService.get('/entitlement/filter/subscribed');
  },
};
