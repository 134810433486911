import authService from '@/services/AuthService';
import contactService from '@/services/ContactService';
import RefreshTokenService from '@/services/RefreshTokenService';

export function beforeEach(to, from, next) {
  if (to.meta.unprotected || authService.isAuthenticated()) {
    contactService.populateContactInfo();
    return authService.authenticateUser().then(async () => {
      if (!authService.isAuthenticated()) {
        try {
          await RefreshTokenService.fetchNewToken();
        } catch (e) {
          console.log('Error calling refresh endpoint');
        }
      }
      next();
    });
  }
  let redirectPath = '/login?return_page=' + to.path;

  return authService
    .authenticateUser()
    .then(async () => {
      if (!authService.isAuthenticated()) {
        try {
          await RefreshTokenService.fetchNewToken();
        } catch (e) {
          console.log('Error calling refresh endpoint');
        }
      }
      if (authService.isAuthenticated()) {
        next();
      } else {
        console.log('Unable To Authenticate!');
        next(redirectPath);
      }
      await contactService.populateContactInfo();
      console.log('SB: successfully populated contact info');
    })
    .catch((error) => {
      console.log('Authentication failed!');
      console.log(error);
      next(redirectPath);
    });
}
