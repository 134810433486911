/*
To add a new configuration property, first add the property here with a reasonable default,
Then add the same property in /public/account-web/account-web.json with a value for local dev,
and finally add the same property in each environment file in fcs-static-assets
with the correct value for that environment.
https://github.ford.com/FCS/fcs-static-assets/tree/master/src/public/fcs-static/config/account-web
 */
const state = {
  notificationServiceBaseUrl: '',
  realTimeNotificationServiceBaseUrl: '',
  depotChargingProductFamily: [],
  publicChargingSkus: [],
  capabilityDialogEnabled: false,
  vehicleSecurityProductSku: '',
  complimentaryBevTrialDays: '',
  nonBulkEnrollableProducts: [],
  areNotificationsEnabled: true,
  isNegativeOptionEnabled: false,
  countriesToShowNegativeOptions: [],
  isBulkEnrollmentErrorPopupEnabled: false,
  fordTelematicsProductFamilyId: '',
  fordTelematicsEssentialsProductFamilyId: '',
  fordTelematicsDashcamTierSkus: [],
  enabledComplimentaryProductCountryCodes: [],
  tsdDspCountries: [],
  fteCountries: [],
  vehicleDataConsentRequired: [],
  includedWithTelematicsSkus: [],
  userNameRegexPattern: '.*',
  hideUUID: false,
  maxVehicleCountForSelectAll: 2000,
  defaultCacheTimeToLive: 3600000,
  companyLeadBaseUrl: '/company-lead',
  accountApiProxyBaseUrl: process.env.VUE_APP_API_PROXY_BASEURL || '/proxy',
  apigeeApiBaseUrl: 'https://api.stg01e.gcp.ford.com',
  accountApiProxyWithConfiguration: true,
  accountApiProxyHeaders: {},
  authenticationBaseUrl: '',
  loginUrl: '',
  logoutUrl: '',
  fpgSupportedLocales: [],
  fpgSupportedCountries: [],
  reminderFrequencyForFordProGatewayRedirectInDays: 0,
  fleetAccountHost: '',
  fpgRedirectPath: '/marketplace',
  fpgSupportedAuthorities: [],
  fpgForceRefreshUrl: [],
  fpgLaunchedSidebarItems: [],
  fpgApplicationId: '',
  paymentServiceBaseUrl: '',
  equipmentApiBaseUrl: process.env.VUE_APP_API_PROXY_BASEURL || '/proxy',
  equipmentApigeeApiBaseUrl:
    'https://api.stg01e.gcp.ford.com/pro/equipment-api',
  accountStaticBaseUrl:
    process.env.VUE_APP_STATIC_ASSET_BASEURL ||
    'https://fleetaccount.ford.com/account-api',
  plugInDeviceApiBaseUrl: '/proxy/pro/dm-plug-in-device-api',
  plugInDeviceApigeeApiBaseUrl:
    'https://api.stg01e.gcp.ford.com/pro/dm-plug-in-device-api',
  translationBaseUrl: '/proxy/pro/dm-translations-api',
  translationApigeeApiBaseUrl:
    'https://api.stg01e.gcp.ford.com/pro/dm-translations-api',
  autoSubscribeApigeeApiBaseUrl: 'https://api.stg01e.gcp.ford.com/pro/',
  equipmentApiProxyHeaders: {},
  plugInDeviceApiProxyHeaders: {},
  translationHeaders: {},
  equipmentApiProxyWithConfiguration: true,
  plugInDeviceApiProxyWithConfiguration: true,
  translationApiProxyWithConfiguration: true,
  autosubscribeApiProxyWithConfiguration: true,
  shouldShowVehicleSelectionUpgradeStep: false,
  hideFordProRedirectCancelButton: false,
  showFailedEnrollmentCancelButton: true,
  maxUpgradableVehicleCount: 500,
  fordProSecondaryConsentEnabled: false,
  fordProUrlSchemeAndSubDomain: '',
  fordProDomains: {},
  retrieveVehicleFiltersFromServices: false,
  enableRecaptcha: true,
  recaptchaSiteKey: '',
  iceSkus: [],
  bevSkus: [],
  countTypeToSubscriptionStatusMap: {},
  showGroupEquipment: true,
  isIamBff: false,
};

export { state };
