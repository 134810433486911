import { render, staticRenderFns } from "./TheSidebar.vue?vue&type=template&id=bb216d74&scoped=true&"
import script from "./TheSidebar.vue?vue&type=script&lang=js&"
export * from "./TheSidebar.vue?vue&type=script&lang=js&"
import style0 from "./TheSidebar.vue?vue&type=style&index=0&id=bb216d74&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb216d74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VFlex,VIcon,VLayout,VList,VListTile,VListTileAction,VListTileContent,VListTileTitle,VNavigationDrawer})
