import configuration from '@/store/configuration';

export const redirectToIamBff = (config, apigeeUrl) => {
  let decodedConfigUrl = decodeURIComponent(config.url);
  try {
    const urlObj = new URL(config.url);
    decodedConfigUrl =
      urlObj.pathname.replace(new RegExp('^/[^/]+'), '') + urlObj.search;
  } catch {}
  if (process.env.VUE_APP_DOMAIN_PATH.includes('localhost')) {
    config.baseURL = '/fleet-account' + config.baseURL;
  } else {
    let fordProDomain = configuration.state.fordProDomains['DEFAULT'];
    let host = window.location.hostname.includes('stage')
      ? 'stage.' + fordProDomain
      : fordProDomain;
    let encodedUrl = encodeURIComponent(apigeeUrl);
    config.baseURL =
      'https://api.' + host + '/bff/v1/proxy?resourceUrl=' + encodedUrl;
    config.url = encodeURIComponent(decodedConfigUrl);
  }
};
