// Important!!!
// If you are modifying this file,
// be VERY SURE that the thing you are adding is in fact a constant, and has no likelihood of changing for any reason.
// Things that are not constants:
//   - productSkus
//   - ids
//   - urls
//   - anything else that could vary between environments
//
// If you are trying to add one of the above things, please instead add them to the front end configuration
// by following the instructions in @/src/store/configuration/state.js.

export const AccountConstants = Object.freeze({
  ACCOUNT_API_PATH: '/account-api',
  ADDRESS_VALIDATION_ERROR: 'Provided address is not valid',
  MAX_NAME_LENGTH: 255,
  MAX_EQUIPMENT_TYPE_LENGTH: 50,
  MAX_EMPLOYEE_ID_LENGTH: 20,
  MAX_COUNTRY_CODE_LENGTH: 2,
  MAX_DRIVER_PIN_LENGTH: 4,
  MIN_DRIVER_PIN_LENGTH: 4,
  MAX_TAX_ID_LENGTH: 20,
  MAX_DISPLAYED_NOTIFICATIONS: 2,
  MAX_PRODUCTS_QUANTITY: 999,
  MIN_PRODUCTS_QUANTITY: 1,
  VEHICLE_PRODUCT_LEVEL: 'Vehicle',
  ACCOUNT_PRODUCT_LEVEL: 'Account',
  DEFAULT_CURRENCY_CODE: 'USD',
  ENROLLMENT_STATUS_ACTION: 'ACTION',
  ENROLLMENT_STATUS_PROCESSING: 'PROCESSING',
  ENROLLMENT_STATUS_NONE: 'NONE',
  ENROLLMENT_STATUS_PROCESSING_SUBSCRIBE: 'PROCESSING_ENROLLMENT',
  ENROLLMENT_STATUS_PROCESSING_UNSUBSCRIBE: 'PROCESSING_UNENROLLMENT',
  ENROLLMENT_STATUS_FAILED_TO_SUBSCRIBE: 'ENROLLMENT_ALERT',
  ENROLLMENT_STATUS_FAILED_TO_UNSUBSCRIBE: 'UNENROLLMENT_ALERT',
  TRASH_ACTION_MOVE_TO_VEHICLES: 'MoveToVehicles',
  TRASH_ACTION_PERMANENTLY_DELETE: 'PermanentlyDelete',
  TRASH_ACTION_RETRY_UNSUBSCRIBE: 'RetryUnsubscribe',
  DECOMMISSION_ACTION_MOVE_TO_EQUIPMENT: 'MoveToEquipment',
  DECOMMISSION_ACTION_PERMANENTLY_DELETE: 'PermanentlyDeleteEquipment',
  OTA_BLOCK_SKU: 'SKU-00000245',
  TELEMATICS_DASH_CAM_SKU_BASIC: 'SKU-P0000518',
  TELEMATICS_DASH_CAM_SKU_ENHANCED: 'SKU-P0000519',
  TELEMATICS_ASSET_TRACKING_SOFTWARE: 'SKU-P0000706',
  WEX_FUEL_CARD_SKU: 'SKU-00000179',
  WEX_FUEL_CARD_URL: 'https://fordfleetcustomers.wexinc.com',
  FORD_TELEMATICS_PRODUCT_FAMILY_NAME: 'Ford Pro™ Telematics',
  CONTACT_FCS_URL: 'https://www.commercialsolutions.ford.com/contact-us',
  MARKETPLACE_FCS_URL: 'https://fordpro.com/en-us/intelligence',
  SHIPPING_PRODUCT_SKU: 'SKU-00000164',
  DRIVER_PRODUCT_SKU: 'SKU-00000169',
  FORD_TELEMATICS_ESSENTIALS_PRODUCT_FAMILY_NAME:
    'Ford Pro™ Telematics Essentials',
  FORD_TELEMATICS_30S_PRODUCT_SKU: 'SKU-00000021-A',
  HOME_CHARGING_SKUS: ['SKU-00000221', 'SKU-00000222'],
  DEPOT_CHARGING_SKUS: [
    'SKU-00000090',
    'SKU-00000091',
    'SKU-0000275',
    'SKU-0000276',
    'SKU-0000278',
    'SKU-0000279',
  ],
  DRIVE_APP_IMAGE_URL: '/images/FordTelematicsDriveApp@3x.png',
  copyrightYear: 2022,
  WATCH_VIDEO_URL: 'WATCH_VIDEO_URL',
  PRODUCT_DEMO_LEAD_TYPE: 'PRODUCT_DEMO',
  ALL_VEHICLE_GRID_COLUMNS: [
    { code: 'Status', name: 'vehicle.grid.headers.status' },
    { code: 'ID', name: 'vehicle.grid.headers.name' },
    { code: 'VIN', name: 'vehicle.grid.headers.vin' },
    { code: 'License Plate', name: 'vehicle.grid.headers.licensePlate' },
    {
      code: 'License Expiration',
      name: 'vehicle.grid.headers.licensePlateExpirationDate',
    },
    { code: 'State', name: 'vehicle.grid.headers.licensePlateState' },
    { code: 'Products', name: 'vehicle.grid.headers.products' },
    { code: 'Groups', name: 'vehicle.grid.headers.groups' },
    { code: 'Make', name: 'vehicle.grid.headers.make' },
    { code: 'Model', name: 'vehicle.grid.headers.model' },
    { code: 'Year', name: 'vehicle.grid.headers.year' },
    { code: 'Hardware', name: 'vehicle.grid.headers.hardware' },
    { code: 'Fuel', name: 'vehicle.grid.headers.fuelType' },
    { code: 'Actions', name: 'vehicle.grid.headers.actions' },
  ],
  ALL_OWNER_VEHICLE_GRID_COLUMNS: [
    { code: 'ID', name: 'vehicle.grid.headers.name' },
    { code: 'VIN', name: 'vehicle.grid.headers.vin' },
    { code: 'License Plate', name: 'vehicle.grid.headers.licensePlate' },
    {
      code: 'License Expiration',
      name: 'vehicle.grid.headers.licensePlateExpirationDate',
    },
    { code: 'State', name: 'vehicle.grid.headers.licensePlateState' },
    { code: 'Make', name: 'vehicle.grid.headers.make' },
    { code: 'Model', name: 'vehicle.grid.headers.model' },
    { code: 'Year', name: 'vehicle.grid.headers.year' },
    { code: 'Hardware', name: 'vehicle.grid.headers.hardware' },
    { code: 'Consent Status', name: 'vehicle.grid.headers.consentStatus' },
    { code: 'Actions', name: 'vehicle.grid.headers.actions' },
  ],
  ALL_USER_GRID_COLUMNS: [
    { code: 'Last Name', name: 'user.grid.headers.lastName' },
    { code: 'First Name', name: 'user.grid.headers.firstName' },
    { code: 'Email', name: 'user.grid.headers.email' },
    { code: 'Employee Id', name: 'user.grid.headers.employee' },
    { code: 'Applications', name: 'user.grid.headers.applications' },
    { code: 'Status', name: 'user.grid.headers.status' },
    { code: 'Phone Number', name: 'user.grid.headers.phoneNumber' },
    { code: 'Last Login', name: 'user.grid.headers.lastLogin' },
    { code: 'Actions', name: 'user.grid.headers.actions' },
  ],
  DRIVE_APP_APPLICATION_NAME: 'Ford Pro™ Telematics Drive App',
  DRIVE_APP_SETTING_NAME: 'drive_app_enabled',
  DRIVE_APP_SETTING_ENABLED_VALUE: 'enabled',
  DRIVE_APP_SETTING_DISABLED_VALUE: 'disabled',
  DRIVE_APP_URL: '/settings/driver-app',
  VEHICLE_GRID_COLUMNS_URL: '/settings/vehicle-grid-columns',
  USER_GRID_COLUMNS_URL: '/settings/user-grid-columns',
  OWNER_VEHICLE_GRID_COLUMNS_URL: '/settings/owner-vehicle-grid-columns',
  VEHICLE_STATE_CATEGORY: ['CCS', 'CCS unknown'],
  inviteStatus: {
    NOT_INVITED: 'NOT_INVITED',
    INVITED: 'INVITED',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  },
  DISPLAYABLE_ENROLLMENT_MESSAGE_CODES: [
    '1001',
    '1003',
    '1004',
    '1005',
    '8010',
    '8030',
    '9999',
  ],
  ONE_TRUST: {
    ENABLE: true,
    DATA_DOMAIN_SCRIPT: '474f6f1e-8e77-40c9-97fd-ab58b6e08de0',
    SCRIPT_ATTR: {
      MAIN_ID: 'oneTrust',
      OPTANON_ID: 'oneTrustOptanonWrapper',
    },
    EXPIRATION_IN_MINUTES: 525600,
    COOKIE_GROUPS: {
      STRICTLY_NECESSARY: 'strictly_necessary',
      PERFORMANCE: 'performance',
      FUNCTIONALITY: 'functionality',
      TARGETING: 'targeting',
    },
    COOKIE_KEYS: {
      COOKIE_BANNER_INTERACTED: 'cookieBannerInteracted',
      COOKIE_PREFERENCES: 'cookiePreferences',
      OTP_TIMESTAMP_REGISTRATION: 'otpTSreg',
      OTP_TIMESTAMP_PASSWORD_RESET: 'otpTSpwrt',
    },
  },
  ADOBE_DTM: {
    HEADER_ID: 'adobeDTMHeader',
    FOOTER_ID: 'adobeDTMFooter',
    RAD_UI_SCRIPT_ID: 'radUIScript',
    URL_STAGE:
      '//assets.adobedtm.com/248d63c2ff6b/082dbebebb71/launch-c6daa86eea55-staging.min.js',
    URL_PROD:
      '//assets.adobedtm.com/248d63c2ff6b/082dbebebb71/launch-2de1aecb6f7d.min.js',
  },
  productEnrollmentStatusResponse: {
    READY_TO_SUBSCRIBE: 'readyToSubscribe',
    SUBSCRIBED: 'subscribed',
    NOT_CAPABLE: 'notCapable',
    PROCESSING: 'processing',
    NOT_IN_DEPOT: 'notInDepot',
  },
  VEHICLE_IDS: 'VehicleIds',
  productEnrollmentCountHTMLElement: {
    SPAN: 'span',
    ROUTER_LINK: 'router-link',
    ANCHOR: 'a',
  },
  MAX_RFID_LENGTH: 255,
  COUNTRY_CODES: ['USA', 'CAN'],
  BILLING: {
    AMOUNT_TYPE: {
      FULL: 'FULL',
      CUSTOM: 'CUSTOM',
    },
    FEATURE: {
      PAY_MY_BILL: 'PAY_MY_BILL',
      PENDING_PAYMENTS: 'PENDING_PAYMENTS',
    },
    PAYMENT_DISABLED_COMPANY_TYPE: 'Dealer',
    PAYMENT_DISABLED_COUNTRY_CODE: 'USA',
  },
  PAYLOAD_DEGREE: {
    ALL: 2,
    NONE: 0,
    FEW: 1,
  },
  SIGNUP_FORM: {
    COUNTRY_LIST_ISO2: [
      'CA',
      'FR',
      'DE',
      'IT',
      'ES',
      'GB',
      'US',
      'NL',
      'BE',
      'IE',
      'CH',
      'AT',
      'DK',
      'NO',
      'PL',
    ],
  },
  DEFAULT_LANGUAGE: 'en-US',
  MONTHS: [
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ],
  PRIVACY_POLICY_URL: {
    ENGLISH_US: 'https://www.ford.com/help/privacy/',
    CANADA_FR: 'https://fr.ford.ca/help/privacy/',
    ENGLISH_UK:
      'https://www.ford.co.uk/useful-information/terms-and-privacy-policy-hub#PrivacyPolicies',
    GERMAN:
      ' https://www.ford.de/footer/uebersicht-datenschutz-rechtliche-hinweise#PrivacyPolicies',
    ITALIAN:
      'https://www.ford.it/informazioni-utili/termini-e-privacy#PrivacyPolicies',
    SPANISH:
      'https://www.ford.es/informacion-util/terminos-y-condiciones-y-politica-de-privacidad#PrivacyPolicies',
    FRENCH:
      'https://www.ford.fr/informations-utiles/politique-de-confidentialite-termes-et-conditions#PrivacyPolicies',
    NETHERLANDS:
      'https://www.nl.ford.be/belangrijke-informatie/voorwaarden-en-privacy',
    BELGIUM:
      'https://www.fr.ford.be/informations-utiles/conditions-et-vie-privee',
  },
  USA_PRIVACY_POLICY: 'USA',
  CAN_PRIVACY_POLICY: 'CAN',
  RFID_ERROR_CODES: [40, 50, 60],
  REMOVE_SPECIAL_CHARACTERS_REGEX: new RegExp(/[.[\s\]']/g),
  PAYMENT_TERMS_AND_CONDITIONS_URL_PREPROD:
    'https://wwwqa.payments.ford.com/legal/PaymentTsAndCsEN-US.pdf',
  PAYMENT_TERMS_AND_CONDITIONS_URL_PROD:
    'https://www.payments.ford.com/legal/PaymentTsAndCsEN-US.pdf',
  PAYMENT_SCRIPT_URL_STAGE_INTERNAL:
    'https://payment-service-stage.apps.pd01i.edc2.cf.ford.com/v1/payment.js',
  PAYMENT_SCRIPT_URL_STAGE_EXTERNAL:
    'https://payment-service-stage.apps.pd01e.edc2.cf.ford.com/v1/payment.js',
  PAYMENT_SCRIPT_URL_PROD:
    'https://payment-service.apps.pd01e.edc2.cf.ford.com/v1/payment.js',
  PRODUCTION_HOST_NAMES: [
    'fleetaccount.ford.com',
    'fordpro.com',
    'fordpro.ca',
    'www.fordpro.ca',
    'www.fordpro.com',
  ],
  STAGE_HOST_NAMES: ['fleetaccount-stage.ford.com'],
  PAYMENT_TYPE_EACH: 'EACH',
  PAYMENT_TYPE_E_ACH: 'eACH',
  PAYMENT_TYPE_US_BANK_ACCOUNT: 'us_bank_account',
  PAYMENT_DECIMAL_SEPARATOR_COMMA: [
    'de-DE',
    'es-ES',
    'fr-CA',
    'fr-FR',
    'it-IT',
    'nl-NL',
    'da-DK',
    'no-NO',
  ],
  PAYMENT_DECIMAL_SEPARATOR_POINT: ['en-GB', 'en-US'],
  PAYMENT_INPUT_ALLOWED_KEY_NUMBERS: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
  ],
  PAYMENT_INPUT_ALLOWED_KEY_NUMBERS_WITH_BACKSPACE: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    'Backspace',
  ],
  BILLING_PAYMENT_PAY_ANOTHER_WAY: {
    CAN: {
      bankNumber: 'Bank No.: 0270',
      streetDetail: 'P.O. Box 12050, Station A',
      transitNumber: 'Transit No.: 00012',
      branchName: 'Toronto Branch',
      pinDetail: 'M5W 0K5',
      stateDetail: 'Toronto, Ontario',
      companyName: 'Ford Smart Mobility Canada',
      swiftBic: 'SWIFT BIC: CHASCATTCTS',
      bankName: 'JP Morgan Chase Bank',
      accountNumber: 'Account: 4011738648',
    },
    GBR: {
      accountTitle: 'Payment(s) by Bank',
      fordSmartMobility: 'Ford Smart Mobility U.K. LTD',
      infoText:
        'Please include your Ford Smart Mobility account number and the invoice number with your Bank payment',
      accountNumber: 'Account: 67102134',
      bankCode: 'Sort / Bank Code: 60-92-42',
    },
    USA: {
      dealerPayments: 'Dealer Payments',
      customAmountLabel: 'Other Amount',
      bankName: 'JP Morgan Chase Bank',
      streetDetail: '29725 Network Place',
      paymentDetails: 'Payment Details:',
      fullAmountLabel: 'Pay Remaining Balance ({balance})',
      aBAExample: 'ABA: 021000021',
      accountExample: 'Account: 100067682',
    },
  },
  FORD_PAY_UI_CHECKOUT_BUNDLE_URL_STAGE:
    'https://fordpay-components-uat.ford.com/js/fordpay-ui-checkout-bundle.js',
  FORD_PAY_UI_CHECKOUT_BUNDLE_URL_PROD:
    'https://fordpay-components.ford.com/js/fordpay-ui-checkout-bundle.js',
  EUROPE_REGION: 'Europe',
  VIN_DETAIL_FILE_TYPE_NAME: 'vinDetail',
  RESOLUTION_CATEGORY_TYPE: {
    POP_UP: 'POP_UP',
    FLAT_TEXT: 'FLAT_TEXT',
    URL: 'URL',
    UNKNOWN: 'UNKNOWN',
  },
  BILLING_NAVIGATION_DIALOG_BOX_TITLE_NA:
    'Managing your Products & Subscriptions just got easier!',
  BILLING_NAVIGATION_DIALOG_BOX_MESSAGE_NA:
    'In the new Financing & Billing menu, you can quickly view your account summary, manage your payment methods, see your transaction history, and even make payments in different currencies.',
});

export const EquipmentConstants = Object.freeze({
  EQUIPMENT_CAMERA_TYPE: 'Ford Pro™ Telematics Dashcam',
  EQUIPMENT_ASSET_TRACKER_TYPE: 'BeWhere Device',
  EQUIPMENT_TYPE_MAP: new Map([
    ['SKU-P0000518', 'Ford Pro™ Telematics Dashcam'],
    ['SKU-P0000519', 'Ford Pro™ Telematics Dashcam'],
    ['SKU-P0000706', 'BeWhere Device'],
  ]),
  EQUIPMENT_TRANSLATION_MAP: new Map([
    ['SKU-P0000518', 'dashcam'],
    ['SKU-P0000519', 'dashcam'],
    ['SKU-P0000706', 'assetTrackers'],
  ]),
  EQUIPMENT_SUBTITLE: ['SKU-P0000518', 'SKU-P0000519'],
  EQUIPMENT_CUSTOM_EQUIPMENT_TYPE: 'Custom',
  EQUIPMENT_COMPONENT_TYPE_TEXTFIELD: 'textField',
  EQUIPMENT_COMPONENT_TYPE_DROPDOWN: 'dropdown',
  EQUIPMENT_COMPONENT_TYPE_TRANSLATED_DROPDOWN: 'translatedDropdown',
  EQUIPMENT_COMPONENT_TYPE_RANGE_DROPDOWN: 'rangeDropdown',
  EQUIPMENT_COMPONENT_TYPE_YEAR_DROPDOWN: 'yearDropdown',
  EQUIPMENT_COMPONENT_TYPE_TEXT_AREA: 'textArea',
  MAX_NAME_LENGTH: 255,
  MAX_EQUIPMENT_TYPE_LENGTH: 50,
});
