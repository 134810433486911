import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';
import auth from '@/store/auth';

const localityService = {
  getLocals() {
    if (auth.state.isLoggedIn) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/locality'
      );
    } else {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + '/v2/locality'
      );
    }
  },
};

export default localityService;
